import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from '../DataContexts/DataContext';
import './TagManager.css';

const TagManager = () => {
    const { loading, error, tags, updateTag, createTag, deleteTag } = useContext(DataContext); // Add deleteTag
    const [visibleTags, setVisibleTags] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('All');
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editGenre, setEditGenre] = useState('');
    const [newTagName, setNewTagName] = useState('');
    const [newTagGenre, setNewTagGenre] = useState('');
    const [showEditDropdown, setShowEditDropdown] = useState(false);
    const [showCreateDropdown, setShowCreateDropdown] = useState(false);
    const ITEMS_PER_PAGE = 100;

    const [filteredTags, setFilteredTags] = useState([]);

    useEffect(() => {
        setFilteredTags(
            tags
                .filter((tag) =>
                    tag.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                    (selectedGenre === 'All' || tag.type === selectedGenre)
                )
                .sort((a, b) => a.name.localeCompare(b.name))
        );
        setPage(1); // Reset pagination when filters change
    }, [tags, searchQuery, selectedGenre]);

    useEffect(() => {
        setVisibleTags(filteredTags.slice(0, page * ITEMS_PER_PAGE));
    }, [page, filteredTags]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && visibleTags.length < filteredTags.length) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };

    const handleGenreChange = (e) => {
        setSelectedGenre(e.target.value);
        setPage(1);
    };

    const handleCreateTag = async () => {
        if (!newTagName.trim() || !newTagGenre.trim()) {
            alert('Please provide both a name and genre for the tag.');
            return;
        }

        const newTag = { name: newTagName.trim(), type: newTagGenre.trim() };

        try {
            await createTag(newTag); // Call the createTag method from DataContext
            setNewTagName('');
            setNewTagGenre('');
        } catch (error) {
            console.error('Failed to create tag:', error);
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(selectedTag.name);
        setEditGenre(selectedTag.type);
    };

    const handleSaveClick = async () => {
        try {
            await updateTag(selectedTag.id, { name: editName, type: editGenre }); // Call updateTag method
            setIsEditing(false);
            setSelectedTag({ ...selectedTag, name: editName, type: editGenre });
        } catch (error) {
            console.error('Failed to update tag:', error);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditName(selectedTag.name);
        setEditGenre(selectedTag.type);
    };

    const handleDeleteClick = async () => {
        const confirmDelete = window.confirm(
            `Are you sure you want to delete the tag "${selectedTag.name}"?`
        );
        if (confirmDelete) {
            try {
                await deleteTag(selectedTag.id); // Call deleteTag method
                setSelectedTag(null); // Clear selected tag after deletion
            } catch (error) {
                console.error('Failed to delete tag:', error);
            }
        }
    };

    const renderAutosuggest = (inputValue, setInputValue, setDropdownVisibility, isEditingMode) => {
        const uniqueTagTypes = [...new Set(tags.map((tag) => tag.type))];

        const matchingTypes = uniqueTagTypes.filter((type) =>
            type.toLowerCase().includes(inputValue.toLowerCase())
        );

        return (
            <div className="tag-dropdown">
                <label htmlFor="editType"><strong>Type:</strong></label>
                <input
                    type="text"
                    placeholder="Type or select a type"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onFocus={() => setDropdownVisibility(true)}
                    onBlur={() => setDropdownVisibility(false)}
                    className="edit-input"
                />
                {isEditingMode && matchingTypes.length > 0 && (
                    <div className="dropdown-list">
                        {matchingTypes.map((type, index) => (
                            <div
                                key={index}
                                className="dropdown-item"
                                onMouseDown={() => {
                                    setInputValue(type);
                                    setDropdownVisibility(false);
                                }}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    if (loading) {
      return (
        <div>
          <h1>Tag manager</h1>
          <p>Loading data, please wait... 🎵</p>
        </div>
      );
    }
  
    if (error) {
      return (
        <div>
          <h1>Tag manager</h1>
          <p>Error loading data: {error}</p>
        </div>
      );
    }

    return (
        <div className="tag-manager">
            <h3>Tag Manager</h3>

            <div className="filter-container">
                <input
                    type="text"
                    placeholder="Search tags..."
                    className="search-box"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <select
                    className="genre-dropdown"
                    value={selectedGenre}
                    onChange={handleGenreChange}
                >
                    <option value="All">All</option>
                    {[...new Set(tags.map((tag) => tag.type))].map((genre) => (
                        <option key={genre} value={genre}>
                            {genre}
                        </option>
                    ))}
                </select>
            </div>

            <div className="columns">
                <div className="column">
                    <ul className="tag-list-content" onScroll={handleScroll}>
                        {visibleTags.map((tag) => (
                            <li
                                key={tag.id}
                                className={selectedTag?.id === tag.id ? 'selected' : ''}
                                onClick={() => setSelectedTag(tag)}
                            >
                                <span className="tag-name">{tag.name}</span>
                                <span className="tag-type">{tag.type}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="column">
                    {selectedTag && (
                        <div>
                            {!isEditing ? (
                                <>
                                    <p><strong>Name:</strong> {selectedTag.name}</p>
                                    <p><strong>Type:</strong> {selectedTag.type}</p>
                                    <button className="edit-button" onClick={handleEditClick}>
                                        Edit
                                    </button>
                                    <button className="cancel-button edit-button" onClick={handleDeleteClick}>
                                        Delete
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <label htmlFor="editName"><strong>Name:</strong></label>
                                        <input
                                            id="editName"
                                            type="text"
                                            value={editName}
                                            onChange={(e) => setEditName(e.target.value)}
                                            className="edit-input"
                                        />
                                    </div>
                                    <div>
                                        {renderAutosuggest(editGenre, setEditGenre, setShowEditDropdown, showEditDropdown)}
                                    </div>
                                    <div className="button-container">
                                        <button className="edit-button" onClick={handleSaveClick}>
                                            Save
                                        </button>
                                        <button className="cancel-button edit-button" onClick={handleCancelClick}>
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="column">
                    <div>
                        <label htmlFor="newTagName"><strong>Name:</strong></label>
                        <input
                            id="newTagName"
                            type="text"
                            value={newTagName}
                            onChange={(e) => setNewTagName(e.target.value)}
                            className="edit-input"
                            placeholder="Enter tag name"
                        />
                    </div>
                    <div>
                        {renderAutosuggest(newTagGenre, setNewTagGenre, setShowCreateDropdown, showCreateDropdown)}
                    </div>
                    <button className="edit-button" onClick={handleCreateTag}>
                        Create Tag
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TagManager;
