import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from '../DataContexts/DataContext';
import { FaPencilAlt, FaCheck, FaTimes } from 'react-icons/fa';
import CoverArtEditor from '../components/CoverArtEditor'
import './ArtistEdit.css';

const ArtistEdit = ({ artist, onAlbumSelect, onSongSelect, selectedSong, selectedAlbum, deleted }) => {
  const { updateArtist } = useContext(DataContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(artist?.name || '');
  const [songSearchQuery, setSongSearchQuery] = useState('');
  const [filteredAlbums, setFilteredAlbums] = useState([]); // 🔹 Store filtered albums
  const [filteredSongs, setFilteredSongs] = useState([]); // 🔹 Store filtered songs
  const [totalTracks, setTotalTracks] = useState(0); // 🔹 Store total number of tracks

  useEffect(() => {
    if (!artist) return;

    const normalizedSearchQuery = songSearchQuery
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    // 🔹 Filter albums logic
    const newFilteredAlbums = artist.albums.filter((album) => {
      const normalizedAlbumName = album.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      const containsNonDeletedTracks = album.tracks.some(track => !track.deleted);

      return (
        (normalizedAlbumName.includes(normalizedSearchQuery) ||
          album.tracks.some((track) => {
            const normalizedTrackTitle = track.title
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();
            return normalizedTrackTitle.includes(normalizedSearchQuery);
          })) &&
        (deleted || containsNonDeletedTracks) // 🔹 If `deleted === false`, hide albums where all tracks are deleted
      );
    });

    setFilteredAlbums(newFilteredAlbums); // 🔹 Update state with new filtered albums

    // 🔹 Filter songs logic
    const newFilteredSongs = newFilteredAlbums.flatMap((album) =>
      album.tracks.filter((track) => {
        const normalizedTrackTitle = track.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        return (
          normalizedTrackTitle.includes(normalizedSearchQuery) &&
          (deleted || !track.deleted) // 🔹 If `deleted === false`, hide deleted tracks
        );
      })
    );

    // 🔹 Calculate totalTracks
    const total = artist.albums.reduce((count, album) => 
      count + album.tracks.filter(track => deleted || !track.deleted).length // 🔹 Count only non-deleted tracks when `deleted === false`
    , 0);
    setTotalTracks(total); // 🔹 Update totalTracks count
    setFilteredSongs(newFilteredSongs); // 🔹 Update state with new filtered songs
  }, [artist, songSearchQuery, deleted]); // 🔹 Runs whenever artist, search, or deleted state changes



  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(artist.name);
  };

  const handleSaveArtistNameClick = async () => {
    if (editedName !== artist.name) {
      // Create an updated artist object with the new name
      const updatedArtist = {
        ...artist,
        name: editedName,
      };

      // Call updateArtist with the full artist object
      await updateArtist(updatedArtist);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedName(artist.name); // Revert to the original name if editing is canceled
  };

  const formatDuration = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleAlbumClick = (album) => {
    onSongSelect(null); // Deselect song if an album is selected
    onAlbumSelect(album);
  };

  const handleSongClick = (track) => {
    onAlbumSelect(null); // Deselect album if a song is selected
    onSongSelect(track);
  };


  if (!artist) {
    return <p>Please select an artist to view details.</p>;
  }

  return (
    <div className="artist-edit">
      <div className="artist-header">
        <CoverArtEditor resourceId={artist.id} template="artist"/>
        <div className="artist-details">
          {isEditing ? (
            <div className="edit-mode">
              <input type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} className="name-input" />
              <FaCheck onClick={handleSaveArtistNameClick} className="icon save-icon" />
              <FaTimes onClick={handleCancelClick} className="icon cancel-icon" />
            </div>
          ) : (
            <div className="display-mode">
              <h3 className="artist-name">{artist.name}</h3>
              <FaPencilAlt onClick={handleEditClick} className="icon edit-icon" />
            </div>
          )}
          <p className="artist-songs-count">{totalTracks} songs</p> {/* 🔹 Show total tracks */}
        </div>
      </div>

      <input type="text" placeholder="Search albums and songs..." value={songSearchQuery} onChange={(e) => setSongSearchQuery(e.target.value)} className="search-songs-input" />

      <div className="albums-list-container">
        <h4>Albums</h4>
        <ul className="albums-list" style={{ maxHeight: '220px', overflowY: 'auto' }}>
          {filteredAlbums.map((album) => {
            const isFullyDeleted = album.tracks.every(track => track.deleted);
            return (
            <li key={album.id} onClick={() => handleAlbumClick(album)}  className={`list-item ${selectedAlbum?.id === album.id ? 'selected' : ''} ${isFullyDeleted ? 'deleted-artist' : ''}`}>
              <span className="album-name">{album.name}</span>
              <span className="album-tracks">{album.tracks.length} tracks</span>
            </li>)}
          )}
        </ul>
      </div>

      <div className="songs-list-container">
        <h4>Songs</h4>
        <ul className="songs-list" style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {filteredSongs.map((track) => (
            <li
              key={track.id}
              onClick={() => handleSongClick(track)}
              className={`list-item ${selectedSong?.id === track.id ? 'selected' : ''} ${track.deleted ? 'deleted-artist' : ''}`} // 🔹 Apply style for deleted tracks
            >
              <span className="song-title">{track.title}</span>
              <span className="song-duration">{formatDuration(track.durationMs)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};


export default ArtistEdit;
