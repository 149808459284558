import React from "react";
import "./TripleStateCheckbox.css";

const TripleStateCheckbox = ({ value, onChange, label }) => {
  const getNextState = (currentValue) => {
    if (currentValue === false) return true;
    if (currentValue === true) return null; // Neutral state
    return false;
  };

  const handleClick = () => {
    onChange(getNextState(value));
  };

  return (
    <div className="triple-state-checkbox" onClick={handleClick}>
      <div
        className={`checkbox-box ${
          value === true ? "checked" : value === null ? "indeterminate" : ""
        }`}
      />
      <label>{label}</label>
    </div>
  );
};

export default TripleStateCheckbox;
