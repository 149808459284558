import React, { useState, useContext } from 'react';
import { DataContext } from '../DataContexts/DataContext';
import { FaPencilAlt, FaCheck, FaTimes } from 'react-icons/fa';
import CoverArtEditor from '../components/CoverArtEditor'
import './AlbumEdit.css';

const AlbumEdit = ({ selectedArtistId, selectedAlbum }) => {
  const { updateAlbum } = useContext(DataContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(selectedAlbum?.name || '');

  if (!selectedAlbum) {
    return <p>No album selected. Please choose an album to view details.</p>;
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedName(selectedAlbum.name);
  };

  const handleSaveAlbumNameClick = async () => {
    if (editedName !== selectedAlbum.name) {
      await updateAlbum(selectedArtistId, selectedAlbum.id, editedName);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedName(selectedAlbum.name); // Revert to the original name if editing is canceled
  };

  const formatDuration = (durationMs) => {
    const minutes = Math.floor(durationMs / 60000);
    const seconds = Math.floor((durationMs % 60000) / 1000).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div>
      <h2>Album:</h2>
      <div className="album-edit">
        <div className="album-header">
          <CoverArtEditor
            resourceId={selectedAlbum.id}
          />
          <div className="album-details">
            {isEditing ? (
              <div className="edit-mode">
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  className="name-input"
                />
                <FaCheck onClick={handleSaveAlbumNameClick} className="icon save-icon" />
                <FaTimes onClick={handleCancelClick} className="icon cancel-icon" />
              </div>
            ) : (
              <div className="display-mode">
                <h3 className="album-name-edit">{selectedAlbum.name}</h3>
                <FaPencilAlt onClick={handleEditClick} className="icon edit-icon" />
              </div>
            )}
          </div>
        </div>

        {/* Songs List */}
        <div className="songs-list-container">
          <h4>Songs</h4>
          <ul className="songs-list" style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {selectedAlbum.tracks.map((track) => (
              <li key={track.id} 
              className={`list-item ${track?.id === track.id ? 'selected' : ''} ${track.deleted ? 'deleted-artist' : ''}`} >
                <span className="song-title">{track.title}</span>
                <span className="song-duration">{formatDuration(track.durationMs)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AlbumEdit;
