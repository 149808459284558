import React, { useState, useEffect } from 'react';
import './ArtistList.css';

const ArtistList = ({
  artists,
  onSelectArtist,
  selectedArtist,
  searchQuery,
  genre,
  subgenre,
  releaseYear,
  selectedTags,
  neverIdle,
  neverNew,
  onDemand,
  allSongs,
  deleted
}) => {
  const [visibleArtists, setVisibleArtists] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 30;

  useEffect(() => {
    const sortedArtists = [...artists].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const filteredArtists = sortedArtists.filter((artist) => {
      // Normalize artist name and search query for diacritic-insensitive matching
      const normalizedArtistName = artist.name
        .normalize('NFD') // Decompose special characters into base character + diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritic marks
        .toLowerCase(); // Case-insensitive comparison

      const normalizedSearchQuery = searchQuery
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      return (
        normalizedArtistName.includes(normalizedSearchQuery) &&
        (!genre || artist.albums.some((album) =>
          album.tracks.some((track) =>
            track.genre.toLowerCase() === genre.toLowerCase()
          )
        )) &&
        (!subgenre || artist.albums.some((album) =>
          album.tracks.some((track) =>
            track.subgenre.toLowerCase() === subgenre.toLowerCase()
          )
        )) &&
        (!releaseYear || artist.albums.some((album) =>
          album.tracks.some((track) =>
            track.releaseyear === parseInt(releaseYear, 10)
          )
        )) &&
        (selectedTags.length === 0 || selectedTags.every((tag) =>
          artist.albums.some((album) =>
            album.tracks.some((track) => track.tags && track.tags.includes(tag.id))
          )
        )) &&
        (allSongs || // If "All Songs" is checked, skip checkbox filters
          (
            (neverIdle === null || artist.albums.some((album) =>
              album.tracks.some((track) => track.neverIdle === neverIdle)
            )) &&
            (neverNew === null || artist.albums.some((album) =>
              album.tracks.some((track) => track.neverNew === neverNew)
            )) &&
            (onDemand === null || artist.albums.some((album) =>
              album.tracks.some((track) => track.ondemand === onDemand)
            ))
          )
        ) &&
        (deleted || artist.albums.some(album =>
          album.tracks.some(track => !track.deleted) // Keep artists with at least one non-deleted song
        ))
      );
    });


    setVisibleArtists(filteredArtists.slice(0, pageSize * page));
  }, [artists, page, searchQuery, genre, subgenre, releaseYear, selectedTags, neverIdle, neverNew, onDemand, allSongs, deleted]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && visibleArtists.length < artists.length) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <ul className="artist-list-content" onScroll={handleScroll}>
      {visibleArtists.map((artist) => {
        const isFullyDeleted = artist.albums.every(album =>
          album.tracks.every(track => track.deleted) // 🔹 Check if all songs are deleted
        );
        return (
          <li
            key={artist.id}
            className={`${selectedArtist?.id === artist.id ? 'selected' : ''} ${isFullyDeleted ? 'deleted-artist' : ''}`}
            onClick={() => onSelectArtist(artist)}
          >
            {artist.name}
          </li>
        );
      })}
    </ul>
  );
};

export default ArtistList;
