import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import Notification from '../components/Notification';

export const DataContext = createContext({
  artists: [],
  tags: [],
  loading: false,
  tagsLoading: false,
  error: null,
  tagsError: null,
  updateArtist: async () => { },
  createPlaylist: async () => { },
  updatePlaylist: async () => { },
  deletePlaylist: async () => { },
});

const DataProvider = ({ children }) => {
  const { authData } = useContext(AuthContext);
  const [artists, setArtists] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tagsError, setTagsError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now(); // Unique ID for each notification
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const showSuccessNotification = (message) => {
    addNotification(message, 'success');
  };

  const showErrorNotification = (message, requestBody) => {
    addNotification(`${message}. Request: ${JSON.stringify(requestBody)}`, 'error');
  };

  const removeNotification = (id) => {
    // First, add the exit class to trigger the CSS transition
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, exiting: true } : notification
      )
    );

    // Then, after the transition, fully remove the notification
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 300); // Duration should match the CSS transition duration
  };

  useEffect(() => {
    const fetchArtists = async () => {
      if (authData?.accessToken) {
        setLoading(true);
        setError(null);
        try {
          const response = await fetch('https://backend.digitalejukebox.be/artists?includeDeleted=true', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch artist data');
          }

          const data = await response.json();
          setArtists(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchTags = async () => {
      if (authData?.accessToken) {
        setTagsLoading(true);
        setTagsError(null);
        try {
          const response = await fetch('https://backend.digitalejukebox.be/tags', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authData.accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch tags data');
          }

          const data = await response.json();
          setTags(data);
        } catch (error) {
          setTagsError(error.message);
        } finally {
          setTagsLoading(false);
        }
      }
    };

    fetchArtists();
    fetchTags();
  }, [authData?.accessToken]);


  const updateArtist = async (artist) => {
    if (authData?.accessToken) {
      // Extract only the required fields from the artist object
      const simplifiedArtistData = {
        id: artist.id,
        name: artist.name,
        deleted: artist.deleted,
      };

      try {
        const response = await fetch(`https://backend.digitalejukebox.be/artists/${artist.id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(simplifiedArtistData),
        });

        if (!response.ok) {
          throw new Error('Update request failed');
        }

        // Update artist in the local state
        setArtists((prevArtists) =>
          prevArtists.map((a) => (a.id === artist.id ? { ...a, ...simplifiedArtistData } : a))
        );

        showSuccessNotification(`Updated artist: ${artist.name}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(simplifiedArtistData));
      }
    }
  };

  const createArtist = async (artistName) => {
    if (authData?.accessToken) {
      const requestBody = { name: artistName };

      try {
        const response = await fetch('https://backend.digitalejukebox.be/artists/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Failed to create artist');
        }

        const newArtist = await response.json();

        // Add the new artist to the local state
        setArtists((prevArtists) => [...prevArtists, newArtist]);

        showSuccessNotification(`Artist created: ${newArtist.name}`);

        return newArtist; // Return the ID of the newly created artist
      } catch (error) {
        showErrorNotification(error.message, requestBody);
        throw error; // Rethrow the error so the calling code can handle it
      }
    }
  };

  const updateAlbum = async (artistId, id, newName) => {
    if (authData?.accessToken) {
      const requestBody = { name: newName };

      try {
        const response = await fetch(`https://backend.digitalejukebox.be/artists/${artistId}/albums/${id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Update album request failed');
        }

        setArtists((prevArtists) =>
          prevArtists.map((artist) => ({
            ...artist,
            albums: artist.albums.map((album) =>
              album.id === id ? { ...album, name: newName } : album
            ),
          }))
        );

        showSuccessNotification(`Album updated: ${newName}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(requestBody));
      }
    }
  };

  const createAlbum = async (artistId, albumName) => {
    if (authData?.accessToken) {
      const requestBody = { name: albumName };
  
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/artists/${artistId}/albums/`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`Failed to create album for artistId: ${artistId}`);
        }
  
        const newAlbum = await response.json();
  
        // Add the new album to the corresponding artist in the local state
        setArtists((prevArtists) =>
          prevArtists.map((artist) =>
            artist.id === artistId
              ? {
                  ...artist,
                  albums: [...artist.albums, newAlbum],
                }
              : artist
          )
        );
  
        showSuccessNotification(`Album created: ${newAlbum.name} under Artist ID: ${artistId}`);
        return newAlbum; // Return the new album object
      } catch (error) {
        showErrorNotification(error.message, { artistId, albumName });
        throw error; // Rethrow to handle it in the calling code
      }
    }
  };

  const addSongToAlbum = (artistId, albumId, song) => {
    setArtists((prevArtists) =>
      prevArtists.map((artist) =>
        artist.id === artistId
          ? {
              ...artist,
              albums: artist.albums.map((album) =>
                album.id === albumId
                  ? { ...album, tracks: [...album.tracks, song] }
                  : album
              ),
            }
          : artist
      )
    );
  };
  
  

  const updateSongMetadata = async (song) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/tracks/${song.id}/metadata`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(song),
        });

        if (!response.ok) {
          throw new Error('Failed to update song metadata');
        }

        // Update the local artists state with the updated song
        setArtists((prevArtists) =>
          prevArtists.map((artist) => ({
            ...artist,
            albums: artist.albums.map((album) => ({
              ...album,
              tracks: album.tracks.map((track) =>
                track.id === song.id ? { ...track, ...song } : track
              ),
            })),
          }))
        );

        showSuccessNotification(`Song metadata updated for ID: ${song.id}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(song));
      }
    }
  };


  const deleteTrack = async (albumId, trackId) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/albums/${albumId}/tracks/${trackId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Failed to delete track (Album ID: ${albumId}, Track ID: ${trackId})`);
        }
  
        // Update the 'deleted' field in the local state instead of removing the track
        setArtists((prevArtists) =>
          prevArtists.map((artist) => ({
            ...artist,
            albums: artist.albums.map((album) =>
              album.id === albumId
                ? {
                    ...album,
                    tracks: album.tracks.map((track) =>
                      track.id === trackId ? { ...track, deleted: true } : track
                    ),
                  }
                : album
            ),
          }))
        );
  
        showSuccessNotification(`Track successfully marked as deleted (Track ID: ${trackId})`);
      } catch (error) {
        showErrorNotification(error.message, { albumId, trackId });
      }
    }
  };
  

  // Method to create a new tag
  const createTag = async (tagData) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch('https://backend.digitalejukebox.be/tags', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(tagData),
        });

        if (!response.ok) {
          throw new Error('Failed to create tag');
        }

        const newTag = await response.json();

        // Add the new tag to the local state
        setTags((prevTags) => [...prevTags, newTag]);

        showSuccessNotification(`Tag created: ${newTag.name}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(tagData));
      }
    }
  };

  // Method to update an existing tag
  const updateTag = async (tagId, updatedData) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/tags/${tagId}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
          throw new Error('Failed to update tag');
        }

        const updatedTag = await response.json();

        // Update the tag in the local state
        setTags((prevTags) =>
          prevTags.map((tag) => (tag.id === tagId ? { ...tag, ...updatedTag } : tag))
        );

        showSuccessNotification(`Tag updated: ${updatedTag.name}`);
      } catch (error) {
        showErrorNotification(error.message, JSON.stringify(updatedData));
      }
    }
  };

  const deleteTag = async (tagId) => {
    if (authData?.accessToken) {
      try {
        const response = await fetch(`https://backend.digitalejukebox.be/tags/${tagId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete tag');
        }

        // Remove the tag from the local state
        setTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));

        showSuccessNotification(`Tag deleted successfully`);
      } catch (error) {
        showErrorNotification(error.message, { tagId });
      }
    }
  };


  return (
    <DataContext.Provider value={{ artists, tags, loading, tagsLoading, error, tagsError, updateArtist, updateAlbum, updateSongMetadata, createTag, updateTag, deleteTag, createAlbum, createArtist, addSongToAlbum, deleteTrack }}>
      {children}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => removeNotification(notification.id)}
            className={notification.exiting ? 'notification-exit' : ''}
          />
        ))}
      </div>
    </DataContext.Provider>
  );
};

export default DataProvider;
