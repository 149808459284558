import React, { useContext, useState, useMemo, useEffect } from 'react';
import { DataContext } from '../DataContexts/DataContext';
import { PlaylistDataContext } from '../DataContexts/PlaylistDataContext';
import { PlaytreeDataContext } from '../DataContexts/PlaytreeDataContext';
import CoverArtEditor from '../components/CoverArtEditor'
import './PlaylistManager.css';

const PlaylistManager = () => {
    const { artists } = useContext(DataContext); // Access playlists, artists, and createPlaylist
    const { playlists, createPlaylist, updatePlaylist, deletePlaylist } = useContext(PlaylistDataContext); // Access playlists, artists, and createPlaylist
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const [editType, setEditType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedType, setSelectedType] = useState('All');
    const [loadedSongs, setLoadedSongs] = useState([]);
    const [songSearchQuery, setSongSearchQuery] = useState('');
    const [songSearchPlaylistQuery, setSongSearchPlaylistQuery] = useState('');
    const [selectedSongs, setSelectedSongs] = useState([]); // Tracks selected with the "+" button
    const [page, setPage] = useState(1);
    const [songsMarkedForRemoval, setSongsMarkedForRemoval] = useState([]);
    const { playtrees } = useContext(PlaytreeDataContext);

    const ITEMS_PER_PAGE = 50;

    useEffect(() => {
        setSongSearchPlaylistQuery(''); // Reset the search box in the middle
        setSongSearchQuery(''); // Reset the search box on the right
    }, [selectedPlaylist]);

    // State for creating a playlist
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [newPlaylistType, setNewPlaylistType] = useState('');

    // Combine all songs from artists
    const allSongs = useMemo(() => {
        return artists.flatMap((artist) =>
            artist.albums.flatMap((album) =>
                album.tracks.map((track) => ({
                    ...track,
                    artistName: artist.name,
                }))
            )
        );
    }, [artists]);

    // Filter playlists based on search query and selected type
    const filteredPlaylists = useMemo(() => {
        if (!playlists) return [];
        return playlists
            .filter(
                (playlist) =>
                    playlist.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                    (selectedType === 'All' || playlist.playlistType === selectedType)
            )
            .sort((a, b) => a.playlistType.localeCompare(b.playlistType));
    }, [playlists, searchQuery, selectedType]);

    // Filter songs for the third column
    const filteredSongs = useMemo(() => {
        if (!selectedPlaylist) return [];
        return allSongs
            .filter(
                (song) =>
                    !selectedPlaylist.tracks.includes(song.id) &&
                    (song.title.toLowerCase().includes(songSearchQuery.toLowerCase()) ||
                        song.artistName.toLowerCase().includes(songSearchQuery.toLowerCase()))
            )
            .slice(0, page * ITEMS_PER_PAGE);
    }, [allSongs, selectedPlaylist, songSearchQuery, page]);

    const filteredPlaylistSongs = useMemo(() => {
        if (!selectedPlaylist) return [];
        return selectedPlaylist.tracks
            .map((trackId) => allSongs.find((song) => song.id === trackId))
            .filter((track) =>
                track && (
                    track.title.toLowerCase().includes(songSearchPlaylistQuery.toLowerCase()) ||
                    track.artistName.toLowerCase().includes(songSearchPlaylistQuery.toLowerCase())
                )
            );
    }, [selectedPlaylist, allSongs, songSearchPlaylistQuery]);

    // Get CUSTOM_ playlist types for the dropdown
    const customPlaylistTypes = useMemo(() => {
        if (!playlists) return [];
        return [...new Set(playlists.map((playlist) => playlist.playlistType))].filter((type) =>
            type.startsWith('CUSTOM_')
        );
    }, [playlists]);

    // Create a new playlist
    const handleCreatePlaylist = async () => {
        if (!newPlaylistName.trim() || !newPlaylistType) {
            alert('Please enter a name and select a playlist type.');
            return;
        }

        const newPlaylist = {
            name: newPlaylistName.trim(),
            playlistType: newPlaylistType,
            tracks: [],
        };

        try {
            await createPlaylist(newPlaylist); // Call createPlaylist from DataContext
            setNewPlaylistName(''); // Clear name
            setNewPlaylistType(''); // Clear type
        } catch (error) {
        }
    };

    // Enter edit mode
    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(selectedPlaylist.name);
        setEditType(selectedPlaylist.playlistType);
    };

    const handleSaveClick = async () => {
        const updatedTracks = selectedPlaylist.tracks.filter(
            (trackId) => !songsMarkedForRemoval.includes(trackId)
        );

        const updatedPlaylist = {
            ...selectedPlaylist,
            name: editName,
            playlistType: editType,
            tracks: updatedTracks,
        };

        try {
            await updatePlaylist(updatedPlaylist); // Update playlist in DataContext
            setSelectedPlaylist(updatedPlaylist); // Update locally
            setSongsMarkedForRemoval([]); // Clear marked songs
            setIsEditing(false);
        } catch (error) {
            console.error('Failed to save playlist:', error);
        }
    };


    // Cancel editing
    const handleCancelClick = () => {
        setIsEditing(false);
        setEditName(selectedPlaylist.name); // Reset name to the original
        setEditType(selectedPlaylist.playlistType); // Reset type to the original
        setSongsMarkedForRemoval([]); // Clear marked songs
    };


    // Add a song to the intermediate table
    const handleAddSongToIntermediate = (song) => {
        if (!selectedSongs.find((selected) => selected.id === song.id)) {
            setSelectedSongs([...selectedSongs, song]);
        }
    };

    const handleRemoveFromPlaylist = (trackId) => {
        if (songsMarkedForRemoval.includes(trackId)) {
            // Unmark the song for removal
            setSongsMarkedForRemoval(songsMarkedForRemoval.filter((id) => id !== trackId));
        } else {
            // Mark the song for removal
            setSongsMarkedForRemoval([...songsMarkedForRemoval, trackId]);
        }
    };

    const handleAddAllToIntermediate = () => {
        const newSongs = filteredSongs.filter(
            (song) => !selectedSongs.some((selected) => selected.id === song.id)
        );
        setSelectedSongs([...selectedSongs, ...newSongs]);
    };

    // Remove a song from the intermediate table
    const handleRemoveFromIntermediate = (songId) => {
        setSelectedSongs((prevSongs) =>
            prevSongs.filter((song) => song.id !== songId)
        );
    };

    // Add selected songs to the playlist
    const handleAddToPlaylist = async () => {
        if (selectedPlaylist) {
            const updatedPlaylist = {
                ...selectedPlaylist,
                tracks: [...selectedPlaylist.tracks, ...selectedSongs.map((song) => song.id)],
            };
            try {
                await updatePlaylist(updatedPlaylist); // Update playlist in DataContext
                setSelectedPlaylist(updatedPlaylist); // Update locally
                setSelectedSongs([]); // Clear the intermediate table
            } catch (error) {
                console.error('Failed to add songs to playlist:', error);
            }
        }
    };

    // Handle scrolling to load more songs dynamically
    const handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && filteredSongs.length < allSongs.length) {
            setPage((prevPage) => prevPage + 1);
        }
    };


    const handleDeletePlayList = async () => {
        if (selectedPlaylist) {
          const confirmDelete = window.confirm(
            `Are you sure you want to delete the playlist "${selectedPlaylist.name}"?`
          );
          if (confirmDelete) {
            await deletePlaylist(selectedPlaylist.id);
            setSelectedPlaylist(null); // Deselect playtree after deletion
          }
        }
      };

    return (
        <div className="playlist-manager">
            {/* Filter bar and Create Playlist */}
            <div className="filter-container">
                {/* Search Playlists */}
                <input
                    type="text"
                    placeholder="Search playlists..."
                    className="search-box"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <select
                    className="genre-dropdown"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                >
                    {['All', ...new Set(playlists.map((playlist) => playlist.playlistType))].map(
                        (type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        )
                    )}
                </select>

                {/* Create Playlist */}
                <div className="create-playlist-container">
                    <span className="create-playlist-label">Create new playlist:</span>
                    <label>
                        Name:
                        <input
                            type="text"
                            className="create-playlist-name"
                            value={newPlaylistName}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                        />
                    </label>
                    <label>
                        Type:
                        <select
                            className="create-playlist-type"
                            value={newPlaylistType}
                            onChange={(e) => setNewPlaylistType(e.target.value)}
                        >
                            <option value="">Select a type</option>
                            {customPlaylistTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </label>
                    <button className="create-playlist-button" onClick={handleCreatePlaylist}>
                        Create Playlist
                    </button>
                </div>

            </div>

            <div className="columns">
                {/* Left column - Playlist list */}
                <div className="column">
                    <ul className="playlist-list-content">
                        {filteredPlaylists.map((playlist) => (
                            <li
                                key={playlist.id}
                                className={
                                    selectedPlaylist?.id === playlist.id ? 'selected' : ''
                                }
                                onClick={() => {
                                    setSelectedPlaylist(playlist);
                                    setPage(1); // Reset the page for third column scrolling
                                    setLoadedSongs([]);
                                    setSelectedSongs([]); // Clear intermediate table
                                    setIsEditing(false); // Exit edit mode if switching playlists
                                }}
                            >
                                <span className="playlist-name">{playlist.name}</span>
                                <span className="playlist-track-count">
                                    {playlist.tracks.length} {playlist.tracks.length === 1 ? 'track' : 'tracks'}
                                </span>

                            </li>
                        ))}
                    </ul>
                </div>

                {/* Middle column - Playlist details */}
                <div className="column">
                    {selectedPlaylist ? (
                        <div className="playlist-details">
                            {/* Playlist Header */}
                            <div className="playlist-header">
                                <CoverArtEditor
                                    resourceId={selectedPlaylist.id} template="playlist"
                                />
                                <div className="playlist-info">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={editName}
                                                onChange={(e) => setEditName(e.target.value)}
                                                className="edit-input"
                                            />
                                            <select
                                                value={editType}
                                                onChange={(e) => setEditType(e.target.value)}
                                                className="edit-input"
                                            >
                                                {customPlaylistTypes.map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <h4>{selectedPlaylist.name}</h4>
                                            <p>Type: {selectedPlaylist.playlistType}</p>
                                            <p>
                                                Categories:{' '}
                                                {playtrees
                                                    .filter((playtree) =>
                                                        playtree.sublists.includes(selectedPlaylist.id)
                                                    )
                                                    .map((playtree) => playtree.name)
                                                    .join(', ') || 'None'}
                                            </p>
                                        </>
                                    )}
                                </div>
                                {selectedPlaylist.playlistType.startsWith('CUSTOM_') && (
                                    <div className="edit-buttonsPlaytrees">
                                        {isEditing ? (
                                            <>
                                                <button
                                                    className="edit-buttonPlaytrees"
                                                    onClick={handleSaveClick}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="cancel-buttonPlaytrees"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    className="edit-buttonPlaytrees"
                                                    onClick={handleEditClick}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="delete-buttonPlaytrees"
                                                    onClick={handleDeletePlayList}
                                                >
                                                    Delete
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* Songs Header */}
                            <div className="playlist-header">
                                <h5>
                                    Songs in Playlist: {selectedPlaylist.tracks.length} {selectedPlaylist.tracks.length === 1 ? 'track' : 'tracks'}
                                </h5>
                                <input
                                    type="text"
                                    placeholder="Search in playlist..."
                                    className="search-box"
                                    value={songSearchPlaylistQuery}
                                    onChange={(e) => setSongSearchPlaylistQuery(e.target.value)}
                                    style={{ marginLeft: 'auto', width: '200px' }}
                                />
                            </div>

                            {/* Songs Table */}
                            <table className="playlist-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        {isEditing && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPlaylistSongs.map((track) => (
                                        <tr
                                            key={track.id}
                                            style={{
                                                backgroundColor: songsMarkedForRemoval.includes(track.id) ? '#ffcccc' : 'transparent',
                                            }}
                                        >
                                            <td>{track.title}</td>
                                            <td>{track.artistName}</td>
                                            {isEditing && (
                                                <td>
                                                    <button
                                                        className={songsMarkedForRemoval.includes(track.id) ? 'add-button' : 'remove-button'}
                                                        onClick={() => handleRemoveFromPlaylist(track.id)}
                                                    >
                                                        {songsMarkedForRemoval.includes(track.id) ? '+' : '-'}
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>Select a playlist to view details.</p>
                    )}
                </div>



                {/* Right column - Song search and list */}
                <div className="column" onScroll={handleScroll}>
                    {!selectedPlaylist ? (
                        <p>Select a playlist to add songs.</p>
                    ) : selectedPlaylist.playlistType.startsWith('GENERIC') ? (
                        <p>Cannot add songs to GENERIC playlists.</p>
                    ) : (
                        <>
                            {/* Intermediate Table */}
                            {selectedSongs.length > 0 && (
                                <div className="intermediate-table">
                                    <h5>Selected Songs</h5>
                                    <table className="playlist-table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Artist</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedSongs.map((song) => (
                                                <tr key={song.id}>
                                                    <td>{song.title}</td>
                                                    <td>{song.artistName}</td>
                                                    <td>
                                                        <button
                                                            className="remove-button"
                                                            onClick={() => handleRemoveFromIntermediate(song.id)}
                                                        >
                                                            X
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="intermediate-actions">
                                        <button className="edit-button" onClick={handleAddToPlaylist}>
                                            Add to Current Playlist
                                        </button>
                                        <button
                                            className="cancel-button"
                                            onClick={() => setSelectedSongs([])} // Clears the intermediate table
                                        >
                                            Remove All
                                        </button>
                                    </div>
                                </div>
                            )}


                            {/* Song List */}
                            <input
                                type="text"
                                placeholder="Search songs or artists..."
                                className="search-box"
                                value={songSearchQuery}
                                onChange={(e) => setSongSearchQuery(e.target.value)}
                            />
                            <table className="playlist-table">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th>
                                            <button
                                                className="add-button"
                                                onClick={() => handleAddAllToIntermediate()}
                                            >
                                                Add All
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredSongs.map((song) => (
                                        <tr key={song.id}>
                                            <td>{song.title}</td>
                                            <td>{song.artistName}</td>
                                            <td>
                                                <button
                                                    className="add-button"
                                                    onClick={() => handleAddSongToIntermediate(song)}
                                                >
                                                    +
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>

            </div>
        </div>
    );
};

export default PlaylistManager;
