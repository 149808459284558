import React, { createContext, useState, useContext } from "react";
import { AuthContext } from "../AuthContext";
import Notification from "../components/Notification";

export const UploadContext = createContext({
  uploadTrack: async () => { },
  addNotification: () => { },
  AddCoverArtFromTrack: async () => { },
});

const UploadProvider = ({ children }) => {
  const { authData } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const showSuccessNotification = (message) => {
    addNotification(message, "success");
  };

  const showErrorNotification = (message) => {
    addNotification(message, "error");
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const uploadTrack = async (albumId, formData) => {
    if (!albumId) {
      throw new Error("Album ID is required to upload a track.");
    }

    try {
      const response = await fetch(
        `https://backend.digitalejukebox.be/albums/${albumId}/tracks`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData.accessToken}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        let errorMessage = "Unknown error occurred";
        try {
          const errorResponse = await response.json();
          errorMessage = errorResponse.message || JSON.stringify(errorResponse);
        } catch (jsonError) {
          errorMessage = response.statusText;
        }
        throw new Error(`Server Error: ${errorMessage}`);
      }

      const result = await response.json();
      showSuccessNotification(`Track uploaded successfully: ${result.title}`);
      return result;
    } catch (error) {
      showErrorNotification(`Failed to upload track: ${error.message}`);
      throw error;
    }
  };

  const AddCoverArtFromTrack = async (trackId, pictureData) => {
    if (!trackId || !pictureData) {
      showErrorNotification("Track ID and picture data are required");
      return;
    }

    try {
      // Convert base64 string to a Blob
      const byteCharacters = atob(pictureData.split(",")[1]); // Remove the "data:image/...;base64," part
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/jpeg" });

      // Create a file object
      const file = new File([blob], `${trackId}.jpg`, { type: "image/jpeg" });

      // Prepare FormData
      const formData = new FormData();
      formData.append("file", file);

      // Upload to the given API
      const response = await fetch("https://backend.digitalejukebox.be/api/storage/uploadFile/", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authData.accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        let errorMessage = "Failed to upload cover art";
        try {
          const errorResponse = await response.json();
          errorMessage = errorResponse.message || JSON.stringify(errorResponse);
        } catch (jsonError) {
          errorMessage = response.statusText;
        }
        throw new Error(errorMessage);
      }

      showSuccessNotification("Cover art uploaded successfully");
      return;
    } catch (error) {
      showErrorNotification(`Failed to upload cover art: ${error.message}`);
      throw error;
    }
  };

  return (
    <UploadContext.Provider value={{ uploadTrack, addNotification, AddCoverArtFromTrack }}>
      {children}
      <div className="notification-container">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => removeNotification(notification.id)}
          />
        ))}
      </div>
    </UploadContext.Provider>
  );
};

export default UploadProvider;
