import React, { useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { DataContext } from "../DataContexts/DataContext";
import { UploadContext } from "../DataContexts/UploadContext";
import "./TrackUpload.css";

const TrackUpload = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState({
    neverIdle: false,
    onDemand: false,
    neverNew: false,
  });
  const { loading, error, artists, createArtist, createAlbum, addSongToAlbum, tags: allTags } = useContext(DataContext);
  const { uploadTrack, addNotification, AddCoverArtFromTrack } = useContext(UploadContext);
  const [isUploading, setIsUploading] = useState(false);
  const [successCount, setSuccessCount] = useState(0);


  const mapTagIdsToNames = (tagIds) => {
    return tagIds.map((id) => {
      const tag = allTags.find((tag) => tag.id === id);
      return tag ? tag.name : "Unknown";
    });
  };

  const onDrop = (acceptedFiles) => {
    const audioFiles = acceptedFiles.filter(
      (file) => file.name.endsWith(".mp3") || file.name.endsWith(".m4a")
    );

    if (audioFiles.length === 0) {
      alert("Only MP3 or M4A files are allowed!");
      return;
    }

    audioFiles.forEach((file) => {
      window.jsmediatags.read(file, {
        onSuccess: (tag) => {
          const artist = tag.tags.artist || "Unknown";
          const title = tag.tags.title || "Unknown";
          const releaseYear = parseInt(tag.tags.year) || "Unknown";
          const album = tag.tags.album || "Unknown";
          const genre = tag.tags.genre || "";



          let pictureData = "";
          if (tag.tags.picture) {
            const { data, format } = tag.tags.picture;
            let base64String = "";
            for (let i = 0; i < data.length; i++) {
              base64String += String.fromCharCode(data[i]);
            }
            pictureData = `data:${format};base64,${btoa(base64String)}`;
          }

          setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
              file, // Include the file object for uploading
              name: file.name,
              artist,
              title,
              album,
              releaseYear,
              tags: [],
              genre,
              neverIdle: false,
              onDemand: false,
              neverNew: false,
              picture: pictureData, // Store album artwork
            },
          ]);
        },
        onError: (error) => {
          addNotification(`Failed to read metadata for file: ${file.name}`, "error");
        },
      });
    });
  };

  const handleUpload = async () => {
    if (uploadedFiles.length === 0) return;

    setIsUploading(true);
    setSuccessCount(0);
    let currentArtists = [...artists];
    let tempFiles = [...uploadedFiles];
    for (let i = 0; i < tempFiles.length; i++) {
      let fileEntry = tempFiles[i];
      let artistId, albumId;

      // Check if the artist exists
      const matchedArtist = currentArtists.find(
        (artist) => artist.name.toLowerCase() === fileEntry.artist.toLowerCase()
      );

      if (matchedArtist) {
        artistId = matchedArtist.id;
      } else {
        try {
          // Create the artist and get their ID
          const newArtist = await createArtist(fileEntry.artist);
          artistId = newArtist.id;
          currentArtists = [...currentArtists, newArtist];
        } catch (error) {
          continue; // Skip this file
        }
      }

      // Check if the album exists for the artist
      const matchedAlbum = currentArtists
        .find((artist) => artist.id === artistId)
        ?.albums.find((album) => album.name.toLowerCase() === fileEntry.album.toLowerCase());

      if (matchedAlbum) {
        albumId = matchedAlbum.id;
      } else {
        try {
          // Create the album and get its ID
          const newAlbum = await createAlbum(artistId, fileEntry.album);

          currentArtists = currentArtists.map(artist =>
            artist.id === artistId
              ? { ...artist, albums: [...artist.albums, newAlbum] }
              : artist);
          albumId = newAlbum.id;
          
          if (albumId && fileEntry.picture) {
            await AddCoverArtFromTrack(albumId, fileEntry.picture);
          }
        } catch (error) {
          continue; // Skip this file
        }
      }

      // Create the track metadata object
      const trackMetadata = {
        title: fileEntry.title,
        releaseyear: fileEntry.releaseYear,
        tags: fileEntry.tags,
        neverIdle: fileEntry.neverIdle,
        ondemand: fileEntry.onDemand,
        neverNew: fileEntry.neverNew,
        trackLanguage: "",
        genre: fileEntry.genre,
        subgenre: "",
        albumId: albumId
      };

      // Convert the trackMetadata JSON object to a binary format (Blob)
      const trackMetadataBlob = new Blob([JSON.stringify(trackMetadata)], { type: "application/json" });

      // Prepare the form data for upload
      const formData = new FormData();
      formData.append("file", fileEntry.file); // Attach the file object
      formData.append("trackDTO", trackMetadataBlob); // Append track metadata as a binary blob

      try {
        const track = await uploadTrack(albumId, formData);

        addSongToAlbum(artistId, albumId, track);
        tempFiles.splice(i, 1);
        i--;
        setSuccessCount((prevCount) => prevCount + 1);
        setUploadedFiles([...tempFiles]);
        // Upload cover art if it exists
        if (track?.id && fileEntry.picture) {
          await AddCoverArtFromTrack(track.id, fileEntry.picture);
        }
      } catch (error) {
      }
    }
    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "audio/*": [".mp3", ".m4a"],
    },
  });

  const handleTagChangeForAll = (tagName) => {
    const tag = allTags.find((t) => t.name === tagName);
    if (tag) {
      setUploadedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.tags.includes(tag.id) ? file : { ...file, tags: [...file.tags, tag.id] }
        )
      );
    }
  };

  const handleSelectAllChange = (property) => {
    const newValue = !selectAll[property];
    setSelectAll((prev) => ({ ...prev, [property]: newValue }));
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) => ({ ...file, [property]: newValue }))
    );
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleRemoveAllFiles = () => {
    setUploadedFiles([]);
  };

  if (loading) {
    return (
      <div>
        <h1>Track Upload</h1>
        <p>Loading data, please wait... 🎵</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h1>Track Upload</h1>
        <p>Error loading data: {error}</p>
      </div>
    );
  }

  return (
    <div className="track-upload-wrapper">
      {/* Dropzone */}
      <div
        {...getRootProps()}
        className="track-upload-container small-banner"
      >
        <input {...getInputProps()} />
        <div className={`drop-area ${isDragActive ? "active" : ""}`}>
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag one or more MP3 or M4A files to upload</p>
          )}
        </div>
      </div>

      {/* Upload Button */}
      <div className="upload-button-container">
        <span className="upload-status">
          {successCount}/{uploadedFiles.length + successCount}
        </span>
        <button className="upload-button" onClick={handleUpload} disabled={isUploading || addSongToAlbum === 0}>
          {isUploading ? "Uploading..." : "Upload Files"}
        </button>
      </div>

      {/* Table of uploaded files */}
      {uploadedFiles.length > 0 && (
        <table className="uploaded-files-table">
          <thead>
            <tr>
              <th>Cover art</th>
              <th>File Name</th>
              <th>Artist</th>
              <th>Title</th>
              <th>Album</th>
              <th>Release Year</th>
              <th>Genre</th>
              <th>
                Tags
                <div>
                  <input
                    list="all-tags-suggestions"
                    placeholder="Add tag to all"
                    onChange={(e) => {
                      handleTagChangeForAll(e.target.value);
                      e.target.value = "";
                    }}
                  />
                  <datalist id="all-tags-suggestions">
                    {allTags.map((tag) => (
                      <option key={tag.id} value={tag.name} />
                    ))}
                  </datalist>
                </div>
              </th>
              <th>
                Never Idle
                <div>
                  <input
                    type="checkbox"
                    checked={selectAll.neverIdle}
                    onChange={() => handleSelectAllChange("neverIdle")}
                  />
                </div>
              </th>
              <th>
                On Demand
                <div>
                  <input
                    type="checkbox"
                    checked={selectAll.onDemand}
                    onChange={() => handleSelectAllChange("onDemand")}
                  />
                </div>
              </th>
              <th>
                Never New
                <div>
                  <input
                    type="checkbox"
                    checked={selectAll.neverNew}
                    onChange={() => handleSelectAllChange("neverNew")}
                  />
                </div>
              </th>
              <th>
                <button className="remove-all-button" onClick={handleRemoveAllFiles}>
                  🗑️ Remove All
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {uploadedFiles.map((file, index) => (
              <tr key={index}>
                <td>
                  {file.picture ? (
                    <img
                      src={file.picture}
                      alt="Album Art"
                      style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "5px" }}
                    />
                  ) : (
                    "No Image"
                  )}
                </td>
                <td>{file.name}</td>
                <td>{file.artist}</td>
                <td>{file.title}</td>
                <td>{file.album}</td>
                <td>{file.releaseYear}</td>
                <td>{file.genre}</td>
                <td>
                  {mapTagIdsToNames(file.tags).join(", ")} <br />
                  <input
                    list={`tags-suggestions-${index}`}
                    placeholder="Add tag"
                    onChange={(e) => {
                      const tag = allTags.find((t) => t.name === e.target.value);
                      if (tag) {
                        setUploadedFiles((prevFiles) =>
                          prevFiles.map((f, i) =>
                            i === index ? { ...f, tags: [...f.tags, tag.id] } : f
                          )
                        );
                        e.target.value = "";
                      }
                    }}
                  />
                  <datalist id={`tags-suggestions-${index}`}>
                    {allTags.map((tag) => (
                      <option key={tag.id} value={tag.name} />
                    ))}
                  </datalist>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={file.neverIdle}
                    onChange={() =>
                      setUploadedFiles((prevFiles) =>
                        prevFiles.map((f, i) =>
                          i === index ? { ...f, neverIdle: !f.neverIdle } : f
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={file.onDemand}
                    onChange={() =>
                      setUploadedFiles((prevFiles) =>
                        prevFiles.map((f, i) =>
                          i === index ? { ...f, onDemand: !f.onDemand } : f
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={file.neverNew}
                    onChange={() =>
                      setUploadedFiles((prevFiles) =>
                        prevFiles.map((f, i) =>
                          i === index ? { ...f, neverNew: !f.neverNew } : f
                        )
                      )
                    }
                  />
                </td>
                <td>
                  <button
                    className="remove-file-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TrackUpload;
