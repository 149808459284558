import React, { useContext, useState } from 'react';
import './PlaylistHeader.css';
import { DataContext } from "../DataContexts/DataContext";
import PlaylistManager from './PlaylistManager';
import PlaytreeManager from '../PlaytreeManager/PlaytreeManager';

const PlaylistHeader = () => {
  const { loading, error } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState('playlist');

  if (loading) {
    return (
      <div>
        <h1>Playlist manager</h1>
        <p>Loading data, please wait... 🎵</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h1>Playlist manager</h1>
        <p>Error loading data: {error}</p>
      </div>
    );
  }

  return (
    <div className="playlist-navigator-header">
      <div className="tab-container">
        <button
          className={`tab ${activeTab === 'playlist' ? 'active' : ''}`}
          onClick={() => setActiveTab('playlist')}
        >
          Playlists
        </button>
        <button
          className={`tab ${activeTab === 'playtree' ? 'active' : ''}`}
          onClick={() => setActiveTab('playtree')}
        >
          Categorieën
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 'playlist' && (
          <div>
            <PlaylistManager></PlaylistManager>
          </div>
        )}
        {activeTab === 'playtree' && (
          <div>
            <PlaytreeManager></PlaytreeManager>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaylistHeader;
